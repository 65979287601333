import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
// disable-next-line import/no-extraneous-dependencies
import { PhonePe } from 'phonepesdk-web';
// utils
import axios, { endpoints } from 'src/utils/network';
// hooks
import { useLocalStorage } from 'src/hooks/use-local-storage';
//
import { AuthContext } from './auth-context';
import { setHeaders } from './utils';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [cookies, setCookie, removeCookie] = useCookies(['auth']);

  const logs = useLocalStorage('logs', []);

  const navigate = useNavigate();

  const initialize = useCallback(async () => {
    try {
      const { puid } = cookies;
      const { grantToken } = cookies;

      if (puid || grantToken) {
        setHeaders(puid, grantToken);

        // ! Uncomment below for Dummy Auth
        // const response = {
        //   data: {
        //     success: true,
        //     data: {
        //       user: {
        //         displayName: "Hansraj Patel",
        //         email: "hansraj@flyzygo.com",
        //         emailVerified: true,
        //         phoneNumber: "+917357679109",
        //         puid: "7357679109",
        //       }
        //     }
        //   }
        // };

        // ! Uncomment below for Official API Auth
        const response = await axios.get(endpoints.auth.me);

        const profile = response?.data?.data;

        if (!profile) {
          throw new Error('User not found in initialize');
        }

        const { user } = profile;

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
    // eslint-disable-next-line
  }, [cookies.grantToken, cookies.puid]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async () => {
    try {
      // ! Uncomment below for Dummy Auth
      // const grantToken = "GTT";

      // ! Uncomment below for Official API Auth
      const ppSdk = await PhonePe.PhonePe.build(PhonePe.Constants.Species.web);
      const session = await ppSdk.fetchAuthToken();
      const { grantToken, error_code } = session;

      logs.update('logs', [...logs.state, JSON.stringify(session)]);

      if (error_code) {
        throw new Error(error_code);
      }
      if (!grantToken?.length) {
        throw new Error('Empty grant token');
      }
      // Set default axios headers and session storage
      setHeaders(null, grantToken);
      setCookie('grantToken', grantToken, { path: '/' });

      // ! Uncomment below for Dummy Auth
      // const response = {
      //   data: {
      //     success: true,
      //     data: {
      //       user: {
      //         displayName: "Hansraj Patel",
      //         email: "hansraj@flyzygo.com",
      //         emailVerified: true,
      //         phoneNumber: "+917357679109",
      //         puid: "7357679109",
      //       }
      //     }
      //   }
      // };

      // ! Uncomment below for Official API Auth
      const response = await axios.get(endpoints.auth.me);

      if (!response?.data?.success)
        throw new Error(response?.data?.error ?? 'Something went wrong !');
      const profile = response?.data?.data;
      if (!profile) {
        throw new Error('User not found');
      }

      const { user } = profile;

      setHeaders(user.puid, grantToken);
      setCookie('puid', user.puid, { path: '/' });
      setCookie('grantToken', grantToken, { path: '/' });

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
      return { success: true, data: { user } };
    } catch (error) {
      console.error('Caught error in login');
      console.error(error);
      logs.update('logs', [...logs.state, JSON.stringify(error)]);
      return { success: false, error: error.message };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  // const register = useCallback(async (email, password, firstName, lastName) => {
  //   const data = {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   };

  //   const response = await axios.post(endpoints.auth.register, data);

  //   const { user } = response.data;

  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user,
  //     },
  //   });
  // }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setHeaders(null, null);
    setCookie('puid', null, { path: '/' });
    setCookie('grantToken', null, { path: '/' });
    dispatch({
      type: 'LOGOUT',
    });
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'phonepe',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      logout,
    }),
    [login, logout, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
