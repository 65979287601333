import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
// utils
import axios, { fetcher, endpoints } from 'src/utils/network';
import { ADDRESSES, AIRPORTS, CITIES, _cabPackages } from 'src/_mock';

// ----------------------------------------------------------------------

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const BOOKING_TYPES = {
  LOCAL: "local",
  AIRPORT: "airport",
}

export function useGetAddresses(query) {

  const URL = query ? `${endpoints.places.map}?query=${query}` : null;

  const fetcherFn = URL ? () => fetcher(URL) : null;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherFn);

  const memoizedValue = useMemo(
    () => ({
      addresses: data?.places?.places || [],
      addressesLoading: isLoading,
      addressesError: error,
      addressesValidating: isValidating,
      addressesEmpty: !isLoading && !data?.places?.places?.length,
    }),
    [data?.places?.places, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export function useGetAirports() {

  const URL = endpoints.places.airport;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      airports: data?.places || [],
      airportsLoading: isLoading,
      airportsError: error,
      airportsValidating: isValidating,
      airportsEmpty: !isLoading && !data?.places?.length,
    }),
    [data?.places, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export function useGetCities() {

  const URL = endpoints.places.city;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      cities: data?.places || [],
      citiesLoading: isLoading,
      citiesError: error,
      citiesValidating: isValidating,
      citiesEmpty: !isLoading && !data?.places?.length,
    }),
    [data?.places, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export function useGetPackages(placeCode) {

  const URL = `${endpoints.cabs.packages}/${placeCode}`;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      packages: data?.data || [],
      packagesLoading: isLoading,
      packagesError: error,
      packagesValidating: isValidating,
      packagesEmpty: !isLoading && !data?.data?.length,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export async function createBooking(payload) {
  try {

    const URL = endpoints.cabs.booking;

    const response = await axios.post(URL, payload);

    return response.data;

  } catch (error) {
    return { success: false, error: error.message };
  }
}

export function useGetBookings() {

  const URL = endpoints.cabs.booking;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      bookings: data?.data || [],
      bookingsLoading: isLoading,
      bookingsError: data?.error ?? error,
      bookingsValidating: isValidating,
      bookingsEmpty: !isLoading && !data?.data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;

}

export function useGetBookingDetail(id) {

  const URL = endpoints.cabs.bookingDetail(id);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      bookingDetail: data?.data,
      bookingDetailLoading: isLoading,
      bookingDetailError: error,
      bookingDetailValidating: isValidating,
    }),
    [data?.data, error, isLoading, isValidating]
  );

  return memoizedValue;

}
