// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/network';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const setHeaders = (puid, grantToken) => {
  puid = puid?.toString() ?? "puid";
  if (puid || grantToken) {
    axios.defaults.headers.common.grantToken = grantToken;
    axios.defaults.headers.common.puid = puid;
  } else {
    delete axios.defaults.headers.common.puid;
    delete axios.defaults.headers.common.grantToken;
  }
};
