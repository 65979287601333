import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useCallback } from 'react';
// hooks
import { useLocalStorage, getStorage } from 'src/hooks/use-local-storage';
import { useRouter } from 'src/routes/hooks';
// mock
import { AIRPORTS } from 'src/_mock';
// api
import { BOOKING_TYPES, useGetAddresses } from 'src/api/cabs';
//
import { CabsContext } from './cabs-context';

// ----------------------------------------------------------------------

const STORAGE_KEY = 'cabs';

const initialState = {
  bookingType: BOOKING_TYPES.AIRPORT,
  hubLocation: null,
  pickupOrDrop: "pickup",
};

export function CabsProvider({ children }) {
  const router = useRouter();

  const { addresses } = useGetAddresses();

  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const onSelectHubLocation = useCallback(
    (hubLocation) => {
      update('hubLocation', hubLocation);
    },
    [update]
  );

  const onSelectBookingType = useCallback(
    (bookingType) => {
      update('bookingType', bookingType);
    },
    [update]
  );

  const onSelectPackage = useCallback(
    (p) => {
      update('selectedPackage', p);
    },
    [update]
  );

  const onSelectPriceOption = useCallback(
    (p) => {
      update('selectedPriceOption', p);
    },
    [update]
  );

  const onSelectPickupOrDrop = useCallback(
    (p) => {
      update('pickupOrDrop', p);
    },
    [update]
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      //
      onSelectHubLocation,
      onSelectBookingType,
      onSelectPackage,
      onSelectPriceOption,
      onSelectPickupOrDrop,
    }),
    [
      onSelectHubLocation,
      onSelectBookingType,
      onSelectPackage,
      onSelectPriceOption,
      onSelectPickupOrDrop,
      state,
    ]
  );

  return <CabsContext.Provider value={memoizedValue}>{children}</CabsContext.Provider>;
}

CabsProvider.propTypes = {
  children: PropTypes.node,
};
