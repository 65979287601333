export function nameInitials(str) {
  // Split the input string into words
  const words = str.split(' ');

  let initials = '';

  words.forEach(word => {
    if (word.length > 0) {
      initials += word[0].toUpperCase();
    }
  });

  return initials;
}